import ManageServices from "./ManageServices";

export const getUserRouting = async (payload) => {
  try {
    const response = await ManageServices.getUserRoutingByID(payload);

    console.log("getUserRouting RESP", response);
    const data = await response.data;
    return data;
  } catch (err) {
    console.log("ERROR in manageData User Permissions", err);
  }
};
export const downloadDashboardData = async (startDate, endDate) => {
  try {
    const from = startDate || "0";
    const to = endDate || "0";
    await ManageServices.downloadDashboard(from, to);
  } catch (err) {
    console.log("ERROR in downloadDashboardData:", err);
  }
};

export const downloadDynamicReport = async (startDate, endDate) => {
  try {
    const from = startDate || "0";
    const to = endDate || "0";
    const response= await ManageServices.downloadReport(from, to);
    return response;
  } catch (err) {
    console.log("ERROR in downloadDynamicReport:", err);
    throw err;
  }
}

export const downloadLastReport = async () => {
  try {
    const reportData = await ManageServices.downloadLastReport();
    return reportData;
  } catch (err) {
    console.log("ERROR in downloadLastReport:", err);
  }
}

// export const getSettingsEmailData = async () => {
//   try {
//     await ManageServices.getSettingsEmail();
//   } catch (err) {
//     console.log("ERROR");
//   }
// };

export const getDashboardData = async (payload) => {
  try {
    const response = await ManageServices.dashboardData(payload);
    // console.log("RESP", response);
    const data = await response.data;

    return data;
  } catch (err) {
    console.log("ERROR in manageData USERs", err);
  }
};
export const getUsersData = async () => {
  try {
    const response = await ManageServices.getUsers();
    // console.log("RESP", response);
    const data = await response.data;

    return data;
  } catch (err) {
    console.log("ERROR in manageData USERs", err);
  }
};
export const getEnterpriseData = async () => {
  try {
    const response = await ManageServices.getEnterprise();
    // console.log("RESP", response);
    const data = await response.data;

    return data;
  } catch (err) {
    console.log("ERROR in manageData USERs", err);
  }
};
export const getManagerData = async () => {
  try {
    const response = await ManageServices.getManagers();
    // console.log("RESP", response);
    const data = await response.data;

    return data;
  } catch (err) {
    console.log("ERROR in manageData USERs", err);
  }
};
export const getEnterpriseUserData = async () => {
  try {
    const response = await ManageServices.getEnterpriseUser();
    // console.log("RESP", response);
    const data = await response.data;

    return data;
  } catch (err) {
    console.log("ERROR in manageData USERs", err);
  }
};
export const getPaginatedManagerData = async (
  pageNo,
  pageSize,
  searchTerm = ""
) => {
  try {
    // Pass the search term to the API
    const response = await ManageServices.getManagersPaginated(
      pageNo,
      pageSize,
      searchTerm
    );
    const data = await response.data;

    return data;
  } catch (err) {
    console.error("Error in getPaginatedManagerData:", err);
  }
};

export const getPaginatedEnterpriseUserData = async (
  pageNo,
  pageSize,
  searchTerm = ""
) => {
  try {
    // Call the paginated API service
    console.log("INSIDE");
    const response = await ManageServices.getEnterpriseUserPaginated(
      pageNo,
      pageSize,
      searchTerm
    );

    // Extract the data from the response
    const data = await response.data;

    return data; // Return the data for further use
  } catch (err) {
    console.error("Error in getPaginatedEnterpriseUserData:", err);
  }
};

export const getCategoriesData = async () => {
  try {
    const response = await ManageServices.getCategories();
    // console.log("RESP", response);
    const data = await response.data;

    return data;
  } catch (err) {
    console.log("ERROR in manageData USERs", err);
  }
};
export const getSubscriptionData = async () => {
  try {
    const response = await ManageServices.getSubscriptions();
    // console.log("RESP", response);
    const data = await response.data;

    return data;
  } catch (err) {
    console.log("ERROR in manageData USERs", err);
  }
};
export const getPackageEntpData = async () => {
  try {
    const response = await ManageServices.getPackageEntp();
    // console.log("RESP", response);
    const data = await response.data;

    return data;
  } catch (err) {
    console.log("ERROR in manageData USERs", err);
  }
};
export const getDepartmentData = async () => {
  try {
    const response = await ManageServices.getDepartment();
    // console.log("RESP", response);
    const data = await response.data;

    return data;
  } catch (err) {
    console.log("ERROR in manageData USERs", err);
  }
};
export const getDepartmentBYIDData = async (payload) => {
  try {
    const response = await ManageServices.getDepartmentBYID(payload);
    // console.log("RESP", response);
    const data = await response.data;

    return data;
  } catch (err) {
    console.log("ERROR in manageData USERs", err);
  }
};
export const getManagerBYIDData = async (payload) => {
  try {
    const response = await ManageServices.getManagerBYID(payload);
    // console.log("RESP", response);
    const data = await response.data;

    return data;
  } catch (err) {
    console.log("ERROR in manageData USERs", err);
  }
};
export const getUSERSBYManagerIDData = async (payload) => {
  try {
    const response = await ManageServices.getUSERSByManagerID(payload);
    // console.log("RESP", response);
    const data = await response.data;

    return data;
  } catch (err) {
    console.log("ERROR in manageData USERs", err);
  }
};
export const getUSERSByDepIDData = async (payload) => {
  try {
    const response = await ManageServices.getUSERSByDepID(payload);
    // console.log("RESP", response);
    const data = await response.data;

    return data;
  } catch (err) {
    console.log("ERROR in manageData USERs", err);
  }
};
export const getReviewsData = async () => {
  try {
    const response = await ManageServices.getReviews();
    // console.log("RESP", response);
    const data = await response.data;

    return data;
  } catch (err) {
    console.log("ERROR in manageData USERs", err);
  }
};
export const getSMEData = async () => {
  try {
    const response = await ManageServices.getSME();
    // console.log("RESP", response);
    const data = await response.data;

    return data;
  } catch (err) {
    console.log("ERROR in manageData USERs", err);
  }
};
export const getReviewsBySMEIDData = async (payload) => {
  try {
    const response = await ManageServices.getReviewsBySMEID(payload);
    // console.log("RESP", response);
    const data = await response.data;

    return data;
  } catch (err) {
    console.log("ERROR in manageData USERs", err);
  }
};
export const getSMERatingData = async (payload) => {
  try {
    const response = await ManageServices.getSMERating(payload);
    // console.log("RESP", response);
    const data = await response.data;

    return data;
  } catch (err) {
    console.log("ERROR in manageData USERs", err);
  }
};

export const getSMECategory = async () => {
  try {
    const response = await ManageServices.getSMECategory();
    console.log("RESP", response);
    const data = await response.data;

    return data;
  } catch (err) {
    console.log("ERROR in manageData USERs", err);
  }
};

// =====================SMEEEE===========================

// =======================================
export const getPermissionData = async (payload) => {
  try {
    const response = await ManageServices.getPermissionbyID(payload);
    // console.log("RESP", response);
    const data = await response.data;

    return data;
  } catch (err) {
    console.log("ERROR in manageData USERs", err);
  }
};
export const getSMEAdminTitle = async (payload) => {
  try {
    const response = await ManageServices.getSMEAdminTitle(payload);
    // console.log("RESP", response);
    const data = await response.data;

    return data;
  } catch (err) {
    console.log("ERROR in manageData USERs", err);
  }
};
export const getSMESMETitle = async (payload) => {
  try {
    const response = await ManageServices.getSMESMETitle(payload);
    // console.log("RESP", response);
    const data = await response.data;

    return data;
  } catch (err) {
    console.log("ERROR in manageData USERs", err);
  }
};

export const getIndividualUser = async () => {
  try {
    const response = await ManageServices.getIndividualUser();
    console.log("RESP", response);
    const data = await response.data;

    return data;
  } catch (err) {
    console.log("ERROR in manageData USERs", err);
  }
};

export const getRemarksData = async (payload) => {
  try {
    const response = await ManageServices.getRemarks(payload);
    // console.log("RESP", response);
    const data = await response.data;

    return data;
  } catch (err) {
    console.log("ERROR in manageData USERs", err);
  }
};

export const getSettingsData = async () => {
  try {
    const response = await ManageServices.getSettings();
    // console.log("RESP", response);
    const data = await response.data;

    return data;
  } catch (err) {
    console.log("ERROR in manageData USERs", err);
  }
};
export const getSettingsByKeyData = async (payload) => {
  try {
    const response = await ManageServices.getSettingsByKey(payload);
    // console.log("RESP", response);
    const data = await response.data;

    return data;
  } catch (err) {
    console.log("ERROR in manageData USERs", err);
  }
};
export const getLanguagesData = async () => {
  try {
    const response = await ManageServices.getLanguages();
    // console.log("RESP", response);
    const data = await response.data;

    return data;
  } catch (err) {
    console.log("ERROR in manageData USERs", err);
  }
};
export const getRewardsData = async () => {
  try {
    const response = await ManageServices.getRewards();
    // console.log("RESP", response);
    const data = await response.data;

    return data;
  } catch (err) {
    console.log("ERROR in manageData USERs", err);
  }
};
export const getCharityData = async () => {
  try {
    const response = await ManageServices.getCharity();
    // console.log("RESP", response);
    const data = await response.data;

    return data;
  } catch (err) {
    console.log("ERROR in manageData USERs", err);
  }
};

export const getUserLibrariesData = async (payload) => {
  try {
    const response = await ManageServices.getLibraries(payload);
    // console.log("RESP", response);
    const data = await response.data;

    return data;
  } catch (err) {
    console.log("ERROR in manageData USERs", err);
  }
};
export const getManagerLibrariesData = async (payload) => {
  try {
    const response = await ManageServices.getManagerLibraries(payload);
    // console.log("RESP", response);
    const data = await response.data;

    return data;
  } catch (err) {
    console.log("ERROR in manageData USERs", err);
  }
};

export const getSubCategoriesData = async (payload) => {
  try {
    const response = await ManageServices.getSubCategories(payload);
    // console.log("RESP", response);
    const data = await response.data;

    return data;
  } catch (err) {
    console.log("ERROR in manageData USERs", err);
  }
};
export const getSubSubCategoriesData = async (payload) => {
  try {
    const response = await ManageServices.getSubSubCategories(payload);
    // console.log("RESP", response);
    const data = await response.data;

    return data;
  } catch (err) {
    console.log("ERROR in manageData USERs", err);
  }
};
export const getSubSubDataBySMEID = async (payload) => {
  try {
    const response = await ManageServices.getSubSubBySMEID(payload);
    // console.log("RESP", response);
    const data = await response.data;

    return data;
  } catch (err) {
    console.log("ERROR in manageData USERs", err);
  }
};
export const getAllSubSubCategoriesData = async () => {
  try {
    const response = await ManageServices.getAllSubSubCategories();
    // console.log("RESP", response);
    const data = await response.data;

    return data;
  } catch (err) {
    console.log("ERROR in manageData USERs", err);
  }
};

export const getAllActiveCategoriesData = async () => {
  try {
    const response = await ManageServices.getAllActiveCategories();
    // console.log("RESP", response);
    const data = await response.data;

    return data;
  } catch (err) {
    console.log("ERROR in manageData USERs", err);
  }
};
export const getTagsData = async () => {
  try {
    const response = await ManageServices.getTags();
    // console.log("RESP", response);
    const data = await response.data;

    return data;
  } catch (err) {
    console.log("ERROR in manageData USERs", err);
  }
};
export const getFlagsData = async () => {
  try {
    const response = await ManageServices.getFlags();
    // console.log("RESP", response);
    const data = await response.data;

    return data;
  } catch (err) {
    console.log("ERROR in manageData USERs", err);
  }
};
export const getUserLibReviewNotesData = async (payload) => {
  try {
    const response = await ManageServices.getUserLibReviewsNotes(payload);
    // console.log("RESP", response);
    const data = await response.data;

    return data;
  } catch (err) {
    console.log("ERROR in manageData USERs", err);
  }
};
export const getManagerLibReviewNotesData = async (payload) => {
  try {
    const response = await ManageServices.getManagerLibReviewsNotes(payload);
    // console.log("RESP", response);
    const data = await response.data;

    return data;
  } catch (err) {
    console.log("ERROR in manageData USERs", err);
  }
};

export const getAllActiveScheduleData = async (payload) => {
  try {
    const response = await ManageServices.getAllActiveSchedule(payload);
    // console.log("RESP", response);
    const data = await response.data;

    return data;
  } catch (err) {
    console.log("ERROR in manageData USERs", err);
  }
};
export const getAllActiveScheduleManagerData = async (payload) => {
  try {
    const response = await ManageServices.getAllActiveScheduleManager(payload);
    // console.log("RESP", response);
    const data = await response.data;

    return data;
  } catch (err) {
    console.log("ERROR in manageData USERs", err);
  }
};
export const getAllTemplatesData = async () => {
  try {
    const response = await ManageServices.getAllTemplates();
    // console.log("RESP", response);
    const data = await response.data;

    return data;
  } catch (err) {
    console.log("ERROR in manageData USERs", err);
  }
};
export const getTemplatesByIDData = async (payload) => {
  try {
    const response = await ManageServices.getTemplatesByID(payload);
    // console.log("RESP", response);
    const data = await response.data;

    return data;
  } catch (err) {
    console.log("ERROR in manageData USERs", err);
  }
};
export const getSMECountData = async (payload) => {
  try {
    const response = await ManageServices.getSMECount(payload);
    // console.log("RESP", response);
    const data = await response.data;

    return data;
  } catch (err) {
    console.log("ERROR in manageData USERs", err);
  }
};
export const getCategoryTreeData = async (payload) => {
  try {
    const response = await ManageServices.getCategoryTree(payload);
    // console.log("RESP", response);
    const data = await response.data;

    return data;
  } catch (err) {
    console.log("ERROR in manageData USERs", err);
  }
};

export const getNotificationsAllData = async (payload) => {
  try {
    const response = await ManageServices.getNotificationsAll(payload);
    // console.log("RESP", response);
    const data = await response.data;

    return data;
  } catch (err) {
    console.log("ERROR in manageData USERs", err);
  }
};

export const getNotificationsLimitData = async (payload) => {
  try {
    const response = await ManageServices.getNotificationsAll(payload);
    // console.log("RESP", response);
    const data = await response.data;

    return data;
  } catch (err) {
    console.log("ERROR in manageData USERs", err);
  }
};

export const getFCMSettingsData = async () => {
  try {
    const response = await ManageServices.getFCMSettings();
    // console.log("RESP", response);
    const data = await response.data;

    return data;
  } catch (err) {
    console.log("ERROR in manageData USERs", err);
  }
};

// dateUtils.js

// export function formatDate(date) {
//   if (!date) return '-';

//   const d = new Date(date);
//   const year = d.getUTCFullYear();
//   const month = String(d.getUTCMonth() + 1).padStart(2, '0');
//   const day = String(d.getUTCDate()).padStart(2, '0');
//   const hours = String(d.getUTCHours()).padStart(2, '0');
//   const minutes = String(d.getUTCMinutes()).padStart(2, '0');

//   return `${year}/${month}/${day} ${hours}:${minutes}`;
// }

export function formatDate(dt) {
  const date = new Date(dt);

  // Convert to local time (EDT)
  const options = {
    timeZone: "America/New_York",
    hour12: false,
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };
  const edtTime = date.toLocaleString("en-US", options);

  console.log("EDT Time:", edtTime);

  return edtTime;
}
